<template>
    <div class="home">
        <div v-if="displayLoadingAnimationComputed">
            <!-- <div class="loading-header" /> -->
            <!-- <div class="loading-progress" /> -->
            <div class="spinner-wrapper">
                <span class="spinner-border text-primary" role="status"></span>
            </div>
        </div>
        <div v-else>
            <branded-header @show-welcome-modal="showModalIconClicked" />
            <introduction-modal
                v-if="showModal"
                :survey="surveyComputed"
                :organization="organizationComputed"
                :modalId="introModalId"
                @update:model-value="closeModal"
            />
            <div v-if="isCompleted" class="questions-wrapper">
                <already-answered-card
                    :expiration-date="expirationDate"
                    :sub-domain="subDomainComputed"
                />
            </div>
            <div
                class="questions-wrapper"
                v-for="(section, i) in sectionsComputed"
                :key="`s${i}`"
            >
                <section-intro :section="section" />
                <div v-for="(question, j) in section.questions" :key="`q${j}`">
                    <question
                        :question="question"
                        :nextQuestionId="getNextQuestionId(i, j)"
                    ></question>
                </div>
            </div>
            <legal-model-accept-card v-if="showLegalAcceptCard" />
            <pop-button
                v-if="!isPreview"
                id="scrollid-submit"
                :label="buttonLabelComputed"
                type="submit"
                :variant="submitButtonVariant"
                :spinner="this.getIsSubmitting"
                :disabled="!isReadyToSubmit && hasBeenSubmitted"
                :onClick="clickSubmitButton"
                :icon="buttonIconComputed"
            />
        </div>
    </div>
</template>
<script>
// @ is an alias to /src
import Question from "@/components/Question.vue";
import { mapGetters, mapActions } from "vuex";
import PopButton from "@/components/PopButton.vue";
import BrandedHeader from "@/components/BrandedHeader.vue";
import LegalModelAcceptCard from "@/components/LegalModelAcceptCard.vue";
import IntroductionModal from "@/components/IntroductionModal.vue";
import SectionIntro from "@/components/SectionIntro.vue";
import AlreadyAnsweredCard from "@/components/AlreadyAnsweredCard.vue";
import { SurveyAnswerStatus } from "../helpers/constants";
import { Functions } from "../helpers/functions";

export default {
    name: "Home",
    components: {
        Question,
        PopButton,
        BrandedHeader,
        LegalModelAcceptCard,
        IntroductionModal,
        SectionIntro,
        AlreadyAnsweredCard,
    },
    props: {
        surveyPushId: {
            type: Number,
            required: false,
            default: null,
        },
        employeeId: {
            type: Number,
            required: false,
            default: null,
        },
        accessKey: {
            type: String,
            required: false,
            default: null,
        },
        isPreview: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    computed: {
        ...mapGetters([
            "getSurveyAnswerBaseModel",
            "isSurveyAnswerBaseModelLoaded",
            "cssThemeIsLoading",
            "isPartiallyAnswered",
            "getFirstUnansweredQuestionIdIgnoreFirst",
            "isReadyToSubmit",
            "getAvailableLanguages",
            "hasBeenSubmitted",
            "getContentOfLegalModel",
            "getIsSubmitting",
            "getSubmitSuccess",
            "isCompleted",
            "getNumberOfUnansweredRequiredQuestions",
            "getFirstRequiredUnansweredQuestionId",
        ]),
        dataParametersComputed() {
            if (this.$route) {
                return { ...this.$route.params, ...this.$route.query };
            }
            if (this.surveyPushId) {
                return {
                    surveyPushId: this.surveyPushId,
                    employeeId: this.employeeId,
                };
            }
            if (this.surveyPushId) {
                return { surveyId: this.surveyId, employeeId: this.employeeId };
            }
            return null;
        },
        subDomainComputed() {
            return Functions.getCompanySubdomain(this.dataParametersComputed);
        },
        displayLoadingAnimationComputed() {
            return (
                !this.isSurveyAnswerBaseModelLoaded || this.cssThemeIsLoading
            );
        },
        sectionsComputed() {
            if (this.isSurveyAnswerBaseModelLoaded) {
                return this.getSurveyAnswerBaseModel.data.sections;
            }
            return [{ questions: [] }];
        },
        questionIdsComputed() {
            if (this.isSurveyAnswerBaseModelLoaded) {
                return this.getSurveyAnswerBaseModel.data.sections
                    .flatMap((s) => s.questions)
                    .map((q) => q.id);
            }
            return [];
        },
        surveyComputed() {
            if (this.isSurveyAnswerBaseModelLoaded) {
                return this.getSurveyAnswerBaseModel.data.survey;
            }
            return {};
        },
        expirationDate() {
            if (this.isSurveyAnswerBaseModelLoaded) {
                return new Date(
                    this.getSurveyAnswerBaseModel.data.survey.expiration
                );
            }
            return new Date();
        },
        organizationComputed() {
            if (this.isSurveyAnswerBaseModelLoaded) {
                return this.getSurveyAnswerBaseModel.data.organization;
            }
            return {};
        },
        showLegalAcceptCard() {
            return this.getContentOfLegalModel !== null;
        },
        showWelcomeModal() {
            if (this.isSurveyAnswerBaseModelLoaded) {
                let status =
                    this.getSurveyAnswerBaseModel.data.survey
                        .surveyAnswerStatus;
                return (
                    status === SurveyAnswerStatus.notAccessed ||
                    status === SurveyAnswerStatus.accessed
                );
            }
            return true;
        },
        //--- Label text
        buttonLabelComputed() {
            let leftToAnswer = this.getNumberOfUnansweredRequiredQuestions;
            // If you have new changes to submit... and are ready to submit
            if (this.getIsSubmitting) {
                return this.$t("survey.isSubmitting");
            } else if (
                this.hasBeenSubmitted &&
                this.getSurveyAnswerBaseModel.changesToSubmit &&
                this.isReadyToSubmit
            ) {
                return this.$t("survey.submitChange");
                // If you not have new changes and are ready to submit
            } else if (this.isReadyToSubmit && this.hasBeenSubmitted == false) {
                return this.$t("survey.submitSurvey");
                // If not every question is answerd
            } else if (
                this.isReadyToSubmit === false &&
                this.getSurveyAnswerBaseModel.changesToSubmit === false &&
                this.hasBeenSubmitted
            ) {
                return this.$t("survey.surveySent");
            } else if (!this.isReadyToSubmit && !this.hasBeenSubmitted) {
                if (leftToAnswer > 0) {
                    return this.$tc("survey.youHaveUnanswered", leftToAnswer);
                }
                return this.$t("survey.acceptThePrivacyPolicy");
            }
            return this.$tc("survey.youHaveUnanswered", leftToAnswer);
        },
        //--- Button icon
        buttonIconComputed() {
            // If you have new changes to submit... and are ready to submit
            if (
                this.hasBeenSubmitted &&
                this.getSurveyAnswerBaseModel.changesToSubmit &&
                this.isReadyToSubmit
            ) {
                return "pulseanimation fal fa-paper-plane";
                // If you not have new changes and are ready to submit
            } else if (this.isReadyToSubmit && this.hasBeenSubmitted == false) {
                return "pulseanimation fal fa-paper-plane";
                // If not every question is answerd
            } else if (
                this.isReadyToSubmit === false &&
                this.getSurveyAnswerBaseModel.changesToSubmit === false &&
                this.hasBeenSubmitted
            ) {
                return "fal fa-check";
            } else if (!this.isReadyToSubmit && !this.hasBeenSubmitted) {
                return "arrowdownanimation  fal fa-angle-double-up";
            } else {
                return "fal fa-hourglass-half";
            }
        },
        submitButtonVariant() {
            return this.getSubmitSuccess ? "success" : "primary";
        },
    },
    data() {
        return {
            showModal: false,
            introModalId: "introduction-modal",
        };
    },
    async mounted() {
        if (this.dataParametersComputed) {
            this.setDataParameters(this.dataParametersComputed);
            if (this.isPreview) {
                this.setIsPreview();
            }
            await this.fetchSurveyAnswerBaseModel();
            if (this.isSurveyAnswerBaseModelLoaded) {
                let status =
                    this.getSurveyAnswerBaseModel.data.survey
                        .surveyAnswerStatus;
                // Sentry.setUser({ id: this.getSurveyAnswerBaseModel.data.employee.id });
                this.showModal =
                    status === SurveyAnswerStatus.notAccessed ||
                    status === SurveyAnswerStatus.accessed;
                if (
                    this.isPartiallyAnswered &&
                    this.getFirstUnansweredQuestionIdIgnoreFirst !== null
                ) {
                    this.$nextTick(() => {
                        setTimeout(() => {
                            const nextQuestionElement = document.getElementById(
                                `scrollid-${this.getFirstUnansweredQuestionIdIgnoreFirst}`
                            );
                            if (!nextQuestionElement) return;

                            const y =
                                nextQuestionElement.getBoundingClientRect()
                                    .top +
                                window.scrollY +
                                -55;
                            window.scrollTo({ top: y });
                        }, 1000);
                    });
                }
            }
        }
    },
    methods: {
        ...mapActions([
            "setDataParameters",
            "fetchSurveyAnswerBaseModel",
            "submitSurveyAnswer",
            "changeLanguage",
            "unansweredHighlighTriggered",
            "setIsPreview",
        ]),
        getNextQuestionId(i, j) {
            if (this.sectionsComputed[i].questions.length > j + 1) {
                return this.sectionsComputed[i].questions[j + 1].id;
            }
            return (
                this.sectionsComputed.find(
                    (s, index) => index > i && s.questions.length > 0
                )?.questions[0]?.id ?? null
            );
        },
        clickSubmitButton() {
            if (!this.isReadyToSubmit && !this.hasBeenSubmitted) {
                let questionId = this.getFirstRequiredUnansweredQuestionId;
                let questionDomId = `scrollid-${questionId}`;
                if (questionId === null) {
                    questionDomId = "tos-container-id";
                }
                this.unansweredHighlighTriggered();
                const questionDomElement =
                    document.getElementById(questionDomId);
                console.log(questionDomId);

                if (!questionDomElement) return;

                const y =
                    questionDomElement.getBoundingClientRect().top +
                    window.scrollY +
                    -110;
                window.scrollTo({ top: y });
            } else {
                this.submitSurveyAnswer();
            }
        },
        showModalIconClicked() {
            //TODO replace this with better handlers
            if (!this.showModal) {
                this.showModal = true;
            }
        },
        closeModal(){
            this.showModal = false;
        }
    },
};
</script>

<style lang="scss">
.questions-wrapper {
    margin-top: calc(54px + 10px + 45px);
}
.questions-wrapper + .questions-wrapper {
    margin-top: 4em;
}
//--- loading screen
.loading-header {
    height: 54px;
    width: 100%;
}
.loading-progress {
    height: 45px;
    width: 100%;
}
.spinner-wrapper {
    margin: 0 auto;
    position: relative;
    height: calc(100vh - 99px);
    > span {
        position: absolute;
        top: calc(50% - 115px);
        left: calc(50% - 20px);
    }
}
</style>